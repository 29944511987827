body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imgCon{width: 100px;height: 120px;line-height: 120px;overflow: hidden;cursor:pointer;}
.imgCon img{width:100px; height:120px;}
.drawEntry{width:60px; height:60px; line-height: 60px; font-size: 18px;margin-right:10px}

*.noselect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  height: 200px;
  padding: 0 10px;
  

}

/* Remove extra left and right margins, due to padding */
.row {margin: 0 -5px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: 200px;
    display: block;
    margin-bottom: 20px;
    
  }
}

/* Style the counter cards */
.card {
    
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  height: 200px;
  text-align: center;
  color: white;
  /* background-color: #221717; */
}
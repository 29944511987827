body{
    overflow-x: hidden;
}

.hrsp{margin: 10px 0;}
.ctrltxt{line-height: 32px;}
.imgcon{width:100px; height:120px; line-height:120px; cursor: pointer;}
label.label{text-align: right;}
.numCon{min-width: 80px; height: 80px; line-height: 80px; text-align: center; font-size: 46px; font-weight: bold;}
.snumCon{min-width: 50px; height: 50px; line-height: 50px; text-align: center; font-size: 35px; font-weight: bold; border:2px solid #5b9bd1;}

  .profile-usermenu ul li {
    border-bottom: 1px solid #f0f4f7;
  }
  
  .profile-usermenu ul li:last-child {
    border-bottom: none;
  }
  
  .profile-usermenu ul li a {
    font-size: 14px;
    font-weight: 400;
  }
  
  .profile-usermenu ul li a i {
    margin-right: 8px;
    font-size: 14px;
  }
  
  .profile-usermenu ul li a:hover {
    background-color: #fafcfd;
  }
  
  .profile-usermenu ul li.active {
    border-bottom: none;
  }
  
  .profile-usermenu ul li.active a {
    border-left: 2px solid #5b9bd1;
    margin-left: -2px;
  }

  #loader{
    position:fixed;
    z-index: 10000;
    top:0;
    left:0;
    width:100%;
    height:100%;
    /*display:none;*/
    background: url("loader.gif") no-repeat center rgba(255,255,255,0.5);
}

.vertical-center {
  margin: 0px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0px;
}

.ck-editor__editable_inline {
  min-height: 250px !important;
}